import React, { Component } from 'react';
import { Row, Col, Card, Button } from 'antd';
import QueueAnim from 'rc-queue-anim';
import ReactPlayer from 'react-player';
import video from '../../images/video.mp4';
import Cover from 'react-video-cover';
class HomeRoot extends Component<{}, { collapsed: boolean; mobile: boolean; resizeNotifier: any }> {
	constructor(props) {
		super(props);
		this.state = {
			collapsed: false,
			mobile: false,
			resizeNotifier: () => {}
		};
	}

	render() {
		const videoOptions = {
			src: video,
			autoPlay: true,
			muted: true,
			loop: true
		};
		return (
			<div
				style={{
					width: '100vw',
					height: '100vh',
					overflowY: 'hidden',
					top: 0,
					left: 0,
					zIndex: -1
				}}
				// className='uk-flex uk-flex-center'
			>
				<Cover
					videoOptions={videoOptions}
					remeasureOnWindowResize
					getResizeNotifier={(resizeNotifier) => {
						this.setState({
							resizeNotifier
						});
					}}
				/>
				{/* <Row className='uk-width-expand' style={{ minHeight: 'calc(100vh)' }}>
					<Col className='player-wrapper' span={24}>
						<ReactPlayer
							className='react-player'
							onError={(error) => {
								console.log(error);
								console.log(error.message);
							}}
							controls={false}
							muted={true}
							url={video}
							volume={0}
							playing
							loop
							height={'100%'}
							width={'100%'}
						/>
					</Col>
				</Row> */}
			</div>

			// <div className="uk-flex uk-flex-center uk-margin-top">
			//   <Row
			//     gutter={32}
			//     type="flex"
			//     justify="center"
			//     align="middle"
			//     className="uk-width-expand"
			//     style={{ minHeight: 'calc(100vh - 64px)' }}
			//   >
			//     <Col md={10} sm={24}>
			//       <div className="uk-flex uk-flex-center">
			//         <QueueAnim delay={300} type={'left'} duration={1500}>
			//           <div key="card">
			//             <Card
			//               style={{
			//                 borderRadius: '10px',
			//                 backgroundImage: `linear-gradient(to bottom right,#373737,#1a1a1a)`,
			//                 boxShadow:
			//                   '0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)',
			//                 WebkitBoxShadow:
			//                   '0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)',
			//                 border: 0,
			//                 maxWidth: '350px',
			//               }}
			//             >
			//               <h2 className="uk-margin-remove" style={{ color: 'white' }}>
			//                 Team Pictures
			//               </h2>
			//               <h2 style={{ color: '#45c5d5' }}>Done Easy</h2>
			//               <p style={{ color: '#D4D4D4' }}>
			//                 Teampics is a photography platform that lets you manage
			//                 events, clients and teams.
			//               </p>
			//               <p style={{ color: '#D4D4D4' }}>
			//                 Sign up today to save on precious time, money and energy!
			//               </p>
			//               <div key="button" className="uk-flex uk-flex-center">
			//                 <Button
			//                   style={{ color: '#45c5d5', height: '40px' }}
			//                   type="default"
			//                 >
			//                   <a href="https://photographer.teampics.org/login">
			//                     Get Started!
			//                   </a>
			//                 </Button>
			//               </div>
			//             </Card>
			//           </div>
			//         </QueueAnim>
			//       </div>
			//     </Col>
			//     <Col md={14} sm={24}>
			//       <QueueAnim delay={400} type={'right'} duration={1500}>
			//         <div
			//           key="card"
			//           className="uk-flex uk-flex-center uk-flex-middle player-wrapper"
			//         >
			//           <ReactPlayer
			//             onError={error => {
			//               console.log(error)
			//               console.log(error.message)
			//             }}
			//             className="react-player"
			//             controls={false}
			//             muted={true}
			//             url={video}
			//             volume={0}
			//             playing
			//             loop
			//             width={this.state.mobile ? '65%' : '75%'}
			//             height="100%"
			//             style={{ borderRadius: '10px', overflow: 'hidden' }}
			//           />
			//         </div>
			//       </QueueAnim>
			//     </Col>
			//   </Row>
			// </div>
		);
	}
}

export default HomeRoot;
