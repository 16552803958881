import React, { Component } from 'react';
import logo from '../favicon.png';
import { Link } from 'gatsby';

type State = {
	open: boolean;
};

const INITIAL_STATE = { open: false, scrolledToTop: true };

class NavBar extends Component<{}, State> {
	constructor(props) {
		super(props);
		this.state = { ...INITIAL_STATE };
	}
	toggle = () => {
		this.setState({ open: !this.state.open });
	};
	render() {
		const { open } = this.state;

		return (
			<div
				className='navbar tp-header is-fixed-top is-transparent'
				id='header'
				style={{ paddingLeft: '40px', paddingRight: '40px' }}
			>
				<div className='navbar-brand'>
					<a href={'https://teampics.org'} className='navbar-item'>
						<div className='uk-flex uk-flex-center uk-flex-middle'>
							<img src={logo} alt='Teampics' style={{ marginBottom: 0 }} />
						</div>
						<Link
							style={{
								fontSize: '20px',
								fontWeight: 'bold',
								color: '#fff',
								marginLeft: '8px'
							}}
							to='/'
						>
							Teampics
						</Link>
					</a>
					<div
						className={open ? 'navbar-burger is-active' : 'navbar-burger'}
						data-target='navMenu'
						onClick={this.toggle}
					>
						<span />
						<span />
						<span />
					</div>
				</div>

				<div
					style={{ backgroundColor: 'transparent' }}
					className={open ? 'navbar-menu is-active' : 'navbar-menu'}
				>
					<div className='navbar-start' />
					<div className='navbar-end tp-header'>
						<a
							href={'https://photographer.teampics.org/login'}
							target='_blank'
							rel='noopener'
							className='navbar-item'
							style={{ fontWeight: 'bold' }}
						>
							Log In
						</a>
						<a
							href={'https://photographer.teampics.org/register'}
							target='_blank'
							rel='noopener'
							className='navbar-item'
							style={{ border: '2px #45C5D5 solid', fontWeight: 'bold' }}
						>
							Sign Up
						</a>
					</div>
				</div>
			</div>
		);
	}
}

export default NavBar;
