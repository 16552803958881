import React from 'react';
import Helmet from 'react-helmet';
import { Layout as ALayout } from 'antd';
import NavBar from './NavBar';

const { Content, Footer } = ALayout;

const mapStateToProps = (state) => {};

const mapDispatchToProps = (dispatch) => ({});

const Layout = ({ children }) => (
	<div>
		<Helmet
			meta={[ { name: 'description', content: 'Sample' }, { name: 'keywords', content: 'sample, something' } ]}
		>
			<html lang='en' />
			<title>Teampics</title>
		</Helmet>
		<ALayout className='layout-background'>
			{/* <Header
        tagName="header"
        className="tp-header"
        style={{
          backgroundColor: 'transparent',
        }}
      >
      </Header> */}
			<NavBar />
			<Content style={{ overflowX: 'hidden' }} tagName='section' className='tp-content'>
				{children}
			</Content>
			<Footer style={{ backgroundColor: '#F1F4F6' }} className='uk-text-center'>
				<div className='uk-flex uk-flex-center'>©2019 All Rights Reserved</div>
			</Footer>
		</ALayout>
	</div>
);

export default Layout;
