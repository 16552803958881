import React from 'react';

import Layout from '../components/layout';
import HomeRoot from '../views/home';

const HomePage = () => (
	<Layout>
		<HomeRoot />
	</Layout>
);

export default HomePage;
